import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
 
function useProjects() {

    const refCompleted = useRef(false);
    const [projectList, setProjectList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchProjects = () => {

        axios.post("https://graphql.prepr.io/a56ffc145fa53d3168a9a3f26c604370e0555fe8ff9db4137e8b3a4c0fa04853", {
            query: `
            {
                Portofolios {
                    items {
                        _id,
                        title,
                        subtitle,
                        link,
                        cover {
                            url
                        },
                        description,
                        tags {
                            body
                        }
                    }
                }
            }
            `
        }).then(({data}) => {
            setIsLoading(isLoading => false);
            setProjectList(projectList => data.data.Portofolios.items);
        });

    }

    useEffect(() => {
        if(refCompleted.current === false) {
            fetchProjects();
        }
        return () => { refCompleted.current = true; }
    }, []);

    return [isLoading, projectList];

}

export default useProjects;