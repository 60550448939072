import { Outlet } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import './Layout.css';
import { motion } from 'framer-motion';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <motion.div 
        className="main-content"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01]
        }}
      > 
        <div className="mx-auto max-w-7xl text-slate-800 dark:text-white">
          <Outlet/>
        </div>
      </motion.div>
    </>
  )
}

export default Layout