import React from 'react';
import AngularLogo from '../assets/images/angular.png';
import NgBootstrapLogo from '../assets/images/ng-bootstrap.svg';
import ReactLogo from '../assets/images/react.webp';
import JavascriptLogo from '../assets/images/javascript.webp';
import TypescriptLogo from '../assets/images/typescript.webp';
import BootstrapLogo from '../assets/images/bootstrap.webp';
import HtmlLogo from '../assets/images/html.webp';
import CssLogo from '../assets/images/css.webp';
import TailwindLogo from '../assets/images/tailwind.webp';
import PhpLogo from '../assets/images/php.png';
import LumenLogo from '../assets/images/lumen.png';
import ExpressLogo from '../assets/images/ExpressJS.webp';
import SocketIOLogo from '../assets/images/socketio.svg';
import { motion } from 'framer-motion';

export default function Techstack() {

    const techstack = [
        {
            logo: AngularLogo,
            text: "Angular"
        }, 
        {
            logo: NgBootstrapLogo,
            text: "Ng Bootstrap"
        }, 
        {
            logo: BootstrapLogo,
            text: "Bootstrap"
        }, 
        {
            logo: TailwindLogo,
            text: "Tailwinds"
        }, 
        {   
            logo: JavascriptLogo,
            text: "Javascript"
        }, 
        {
            logo: TypescriptLogo,
            text: "Typescript"
        },
        {
            logo: ReactLogo,
            text: "React"
        },
        {
            logo: HtmlLogo,
            text: "HTML5"
        },
        {
            logo: CssLogo,
            text: "CSS3"
        },
        {
            logo: PhpLogo,
            text: "PHP"
        },
        {
            logo: LumenLogo,
            text: "Lumen"
        },
        {
            logo: ExpressLogo,
            text: "Express"
        },
        {
            logo: SocketIOLogo,
            text: "Socket.IO"
        }
    ];

    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Tech Stack
                </h1>
            </div>
            <div className="w-full grid grid-cols-2 sm:grid-cols-6 gap-4">

                {techstack.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4">
                        <div className="flex items-center justify-center h-16">
                            <div className="w-2/5">
                                <img className="" src={item.logo} alt={item.text} />
                            </div>
                        </div>
                        <div className="text-center pt-4">
                            {item.text}
                        </div>
                    </div>
                ))}
                

                {/* <div className="bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4">
                    <div className="flex justify-center">
                        <img className="w-2/5" src={AngularLogo} alt="react.js" />
                    </div>
                </div>
                <div className="flex bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4"
                >
                    <div className="flex justify-center">
                        <img className="w-2/5" src={NgBootstrapLogo} alt="react.js" />
                    </div>
                </div>
                <div className="flex bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4"
                >
                    <div className="flex justify-center">
                        <img className="w-2/5" src={BootstrapLogo} alt="react.js" />
                    </div>
                </div>
                <div className="flex bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4"
                >
                    <div className="flex justify-center w-2/5">
                        <img className="" src={JavascriptLogo} alt="react.js" />
                    </div>
                </div> */}
                
                
            </div>
        </div>
    );
}