import React from 'react';
import MandiriLogo from '../assets/company/mandiri.svg';
import TektonikLogo from '../assets/company/tektonik.svg';
import AAALogo from '../assets/company/aaa.svg';
import ProbizLogo from '../assets/company/ppi.svg';
import FrelanceLogo from '../assets/company/freelancer.svg';

export default function Experience() {

    const companies = [
        {
            logo: MandiriLogo,
            position: "Angular Developer",
            company: "PT Bank Mandiri Persero",
            time: "Aug 2021 - Present",
            describe: "Develop Frontend for Branch Delivery System"
        },
        {
            logo: TektonikLogo,
            position: "Fullstack Developer",
            company: "PT Tektonik Creative Indonesia",
            time: "Feb 2020 - Jun 2021",
            describe: "Frontend and Backend Web Development, Ads, and Entertain Web Development"
        },
        {   
            logo:AAALogo,
            position: "Fullstack Developer",
            company: "Accross Asia Assist",
            time: "Sep 2019 - Feb 2020",
            describe: "Manage Healthcare System"
        },
        {   
            logo:ProbizLogo,
            position: "Software Engineering Supervisor",
            company: "PT Probiz Prima Integrasi",
            time: "Aug 2015 - Aug 2019",
            describe: "Human Resource and Payroll System Development"
        },
        {   
            logo:FrelanceLogo,
            position: "Software Developer",
            company: "Freelancer",
            time: "Jun 2014 - Aug 2015",
            describe: "Web, Unity, Software Development, Evoting"
        }
    ];

    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Experience
                </h1>
            </div>
            <div className="w-full grid sm:grid-cols-2 gap-4">
                {companies.map((item, index) => (
                    <div className="flex flex-wrap bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm"
                    key={index}  
                    >
                        <div className="w-1/6 sm:w-1/5">
                            <div className="">
                                <img alt="img" src={item.logo}/>
                            </div>
                        </div>
                        <div className="w-5/6 sm:w-4/5 pl-4 pr-4 pt-0">
                            <h2 className="text-lg font-bold">{item.position}</h2>
                            <h3 className="text-sm font-medium">{item.company}</h3>
                            <div className="pt-4">
                                <span className="inline-block px-2 py-1 text-xs font-semibold text-white bg-slate-800 rounded-full">
                                    {item.time}
                                </span>
                            </div>
                            <div className="pt-4">
                                <p className="text-xs">{item.describe}</p>
                            </div>
                            <div className="pl-4">
                                <ul className="text-slate-400 list-disc">
                                    {/* {item.tags.map((tag, tagIndex) => (
                                        <li key={tagIndex}>{tag.body}</li>
                                    ))} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}