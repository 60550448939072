import React from 'react';
import Navbar from '../components/navbar/Navbar';
import { FaLinkedin, FaGithub, FaInstagram, FaEnvelope } from "react-icons/fa6";
import Buttonicon from '../components/commons/Buttonicon';

export default function Home() {
    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Looking For a Programmer ?
                </h1>
            </div>
            <div className="flex flex-wrap bg-white dark:bg-slate-800 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
                <div className="w-full sm:w-1/2 relative">
                    <h3 className="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">Hi, I'm</h3>
                    <div>
                        <span className="sm:text-2xl text-xl font-bold bg-yellow-300 dark:bg-transparent">Tom Richard</span>
                    </div>
                    <div>
                        <span className="sm:text-3xl text-2xl text-yellow-300 font-bold bg-slate-800">Fullstack Developer</span>
                    </div>
                    <p className="text-slate-700 dark:text-slate-400 mt-2 text-sm">
                        I've started my journey as a programmer 8 years ago. I've worked for many companies, IT Consultant, Insurance, Agency, and Banking.
                    </p>
                    <p className="text-slate-700 dark:text-slate-400 mt-2 text-sm">
                        I'm quite well in improving my knowledge of new technology.
                    </p>
                    <div className="flex w-full justify-center pt-4 pb-4 sm:absolute sm:bottom-0 sm:justify-start">
                        <a href="https://github.com/tomrichardsimatupang" target="_blank">
                            <Buttonicon icon={<FaGithub/>} />
                        </a>
                        <a href="https://www.linkedin.com/in/tom-richard-289090b7" target="_blank">
                            <Buttonicon icon={<FaLinkedin/>} />
                        </a>
                        <a href="https://www.instagram.com/tomrichardsimatupang" target="_blank">
                            <Buttonicon icon={<FaInstagram/>} />
                        </a>
                        <a href="mailto:tomrichardsimatupang@xdevpusaka.com">
                            <Buttonicon icon={<FaEnvelope/>} />
                        </a>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 flex justify-center items-center bg-yellow-100 dark:bg-transparent">
                    <img className="w-2/5" src="https://cdn.xdevpusaka.com/xdevpusaka/tom-richard.png" alt="Tom Richard" />
                </div>
            </div>
        </div>
    );
}