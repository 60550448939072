import React, { useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Switcher from '../commons/Switcher'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Experience', href: '/experience', current: false },
  { name: 'Projects', href: '/projects', current: false },
  { name: 'Tech Stack', href: '/techstack', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Navbar = () => {

    return (
        <Disclosure as="nav" className="bg-white dark:bg-yellow-300 fixed w-full top-0 left-0 z-50">
        {({ open }) => (
            <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-slate-800 hover:bg-slate-800 hover:text-white hover:ring-2 hover:ring-inset ease-in duration-300">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                    <Link to={'/'}>
                    <img
                        className="h-8 w-auto"
                        src="https://cdn.xdevpusaka.com/xdevpusaka/logo.svg"
                        alt="Tom Richard Simatupang"
                    />
                    </Link>
                    </div>
                    <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                        {navigation.map((item) => (
                        <Link 
                            key={item.name} 
                            to={item.href}
                            className={classNames(
                                item.current ? 
                                'bg-yellow-300 text-slate-900 dark:bg-slate-800 dark:text-slate-100': 
                                'text-slate-800 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-800',
                                'rounded-md px-3 py-2 text-sm font-bold'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >{item.name}</Link>
                        ))}
                    </div>
                    </div>
                </div>
                <div className="flex items-center bg-yellow-300 dark:bg-slate-800 rounded-full p-1">
                    <div style={{height: "30px"}}>
                        <Switcher/>
                    </div>
                </div>
                </div>
            </div>
            <Transition
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Disclosure.Panel className="sm:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                        <Link 
                            key={item.name} 
                            to={item.href}
                            className={classNames(
                                item.current ? 
                                'bg-yellow-300 text-slate-900 dark:bg-slate-800 dark:text-slate-100': 
                                'text-slate-800 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-800',
                                'block rounded-md px-3 py-2 text-base font-bold'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >{item.name}</Link>
                    ))}
                    </div>
                </Disclosure.Panel>
            </Transition>
            </>
        )}
        </Disclosure>
    )
}

export default Navbar;