import React from 'react';
import useProjects from '../hooks/useProjects';

function Button({text}) {
    return (
        <button className="bg-yellow-300 hover:bg-yellow-400 text-slate-800 font-bold py-2 px-4 rounded">
            {text}
        </button>
    )
}

export default function Projects() {

    const [isLoading, projectList] = useProjects();

    const CardProject = ({item}) => {
        return (
            <div className="flex flex-wrap bg-white dark:bg-slate-700 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 border border-slate-100 dark:border-slate-700 shadow-sm mb-4">
                <div className="sm:w-2/5">
                    <img alt="img" src={item.cover.url}/>
                </div>
                <div className="pt-4 pl-4 pr-4 sm:pt-0 sm:w-2/5">
                    <h2 className="text-lg font-bold">{item.title}</h2>
                    <h3 className="text-sm font-medium">{item.subtitle}</h3>
                    <div className="pl-4">
                        <ul className="text-slate-400 list-disc">
                            {item.tags.map((tag, tagIndex) => (
                                <li key={tagIndex}>{tag.body}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="pt-4 pl-4 pr-4 sm:pt-0 sm:w-1/5">
                    <a href={item.link} target="_blank"><Button text={'Preview'}/></a>
                </div>
            </div>
        )
    }

    const Cards = () => {
        return (
            projectList.map((item, index) => (
                <CardProject item={item} key={index} />
            ))
        )
    }

    const Loader = () => {
        return (
            <div className="flex justify-center items-center h-52">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-l-4 border-slate-800 dark:border-yellow-200"></div>
            </div>
        )
    }

    return (
        <div className="p-4">
            <div className="pt-4 pb-4">
                <h1 className="sm:text-4xl text-2xl font-bold text-center">
                    Projects
                </h1>
            </div>
            <div className="w-full">
                {isLoading ? <Loader/> : <Cards/>}
            </div>
        </div>
    );
}